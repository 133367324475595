<template>
  <div class="Footer _Footer">
    <div class="fot_box _fot_box">
      <div class="fot_lf _fot_lf">
        <div class="lf_img">
          <img src="../assets/newimg/wx_qr.png" alt="" class="wx_img" />
          <img src="../assets/newimg/zfb_qr.png" alt="" class="zfb_img" />
        </div>
        <div class="lf_tex">
          <div class="tex_lf">
            <div class="til">服务保障</div>
            <div class="lis_img">
              <div class="li_img">
                <img src="../assets/newimg/icono.png" alt="" />快速上门服务
              </div>
              <div class="li_img">
                <img src="../assets/newimg/icont.png" alt="" />官方品质保障
              </div>
              <div class="li_img">
                <img src="../assets/newimg/iconf.png" alt="" />7*24小时售后支持
              </div>
            </div>
          </div>
          <div class="tex_ri">
            <div class="til">服务热线</div>
            <div class="lis_img">
              <div class="li_img">
                <img src="../assets/newimg/iconl.png" alt="" />400-085-0889
              </div>
              <div class="li_img">
                <img src="../assets/newimg/iconb.png" alt="" />9:00 - 21:00
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="fot_ri _fot_ri">
        <img src="../assets/newimg/fotbpri_logo.png" alt="" class="imgo" />
        <div class="ri_lis" @click="handletOTap">
          <img src="../assets/newimg/sbsc_ri.png" alt="" class="imgt" />
        </div>
      </div>
      <div class="fot_top _fot_top">
        <img
          src="../assets/newimg/foot_logo.png"
          alt=""
          class="logo_img _logo_img"
        />
        <div class="_ri_lis" @click="handletOTap">
          <img
            src="../assets/newimg/fot_sbsc.png"
            alt=""
            class="sbsc_img _sbsc_img"
          />
        </div>
      </div>
      <div class="fot_main _fot_main">
        <div class="main_top _main_top">
          快速上门服务 丨 官方品质保障 丨 7*24小时售后支持
        </div>
        <div class="main_bo _main_bo">400-085-0889 丨 9:00 - 21:00</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  methods: {
    handletOTap() {
      const url = "https://www.zujichong.com/";
      window.open(url, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
img {
  pointer-events: none;
}
// 大于等于800px
@media only screen and (min-width: 800px) {
  .Footer {
    width: 100%;
    height: 241px;
    background-color: #1071fe;
    display: flex;
    justify-content: center;
    .fot_box {
      width: 1280px;
      display: flex;
      justify-content: space-between;

      .fot_lf {
        padding-top: 48px;
        display: flex;
        .lf_img {
          display: flex;
          .wx_img {
            width: 238px;
            height: 126px;
            object-fit: cover;
            margin-right: 8px;
          }
          .zfb_img {
            width: 238px;
            height: 126px;
            object-fit: cover;
          }
        }
        .lf_tex {
          display: flex;

          .tex_lf,
          .tex_ri {
            margin-left: 56px;
          }
          .til {
            font-family: Source Han Sans CN;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0em;
            color: rgba(255, 255, 255, 0.5);
            margin-bottom: 24px;
          }
          .lis_img {
            font-family: Microsoft YaHei;
            font-size: 14px;
            font-weight: normal;
            letter-spacing: 0em;
            color: #ffffff;
            .li_img {
              display: flex;
              align-items: center;
              margin-left: 9px;
              margin-bottom: 16px;
              img {
                width: 24px;
                height: 24px;
                object-fit: contain;
                margin-right: 21px;
              }
            }
          }
        }
      }
      .fot_ri {
        display: flex;
        flex-direction: column;
        align-items: center;
        .imgo {
          width: 254px;
          height: 157px;
          object-fit: cover;
        }
        .ri_lis {
          width: 180px;
          height: 48px;
          border-radius: 4px;
          margin-top: -15px;
          cursor: pointer;
        }
        .imgt {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .fot_top,
      .fot_main {
        display: none;
      }
    }
  }
}
// 小于800px
@media only screen and (max-width: 799px) {
  ._Footer {
    width: 100%;
    height: 147px;
    background-color: #1071fe;
    display: flex;
    // flex-direction: column;
    // align-items: center;
    ._fot_box {
      width: 100%;
      padding: 16px 12px;
    }
    ._fot_lf,
    ._fot_ri {
      display: none;
    }
    ._fot_top {
      width: 100%;
      padding-bottom: 18px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px dashed #d8d8d8;
      ._logo_img {
        width: 103px;
        height: 36px;
      }
      ._ri_lis {
        width: 111px;
        height: 32px;
        ._sbsc_img {
          width: 100%;
          height: 100%;
        }
      }
    }
    ._fot_main {
      font-family: Microsoft YaHei;
      font-size: 12px;
      font-weight: normal;
      letter-spacing: 0em;
      color: #fff;
      padding-top: 16px;
      text-align: center;
      ._main_bo {
        line-height: 18px;
        margin-top: 8px;
      }
    }
  }
}
</style>
