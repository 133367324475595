<template>
  <div class="header _header">
    <div class="mainer _mainer">
      <div class="img_lf _img_lf">
        <img src="../assets/newimg/logo.png" alt="" />
      </div>
      <div class="main_ri _main_ri">
        <div class="rilf _rilf">
          <span class="mf">免费咨询：</span>
          <span class="num">400-085-0889</span>
        </div>
        <div class="riri _riri" @click="handletOTap">设备商城</div>
        <div class="ri_lis _ri_lis" @click="handletOTap">
          <img
            src="../assets/newimg/sbsc_btn.png"
            alt=""
            class="riribtn _riribtn"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  methods: {
    handletOTap() {
      console.log("66666666");
      const url = "https://www.zujichong.com/";
      window.open(url, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
img {
  pointer-events: none;
}
// 大于等于800px
@media only screen and (min-width: 800px) {
  .header {
    width: 100%;
    height: 80px;
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: center;
    .mainer {
      width: 1280px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      // background-color: #fff;
      .img_lf {
        // width: 20.24%;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        img {
          width: 259px;
          height: auto;
          object-fit: cover;
        }
      }
      .main_ri {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .rilf {
          display: flex;
          align-items: center;
          font-family: Microsoft YaHei;
          white-space: nowrap;
          .mf {
            font-size: 16px;
            color: #3d3d3d;
          }
          .num {
            font-size: 28px;
            color: #ff6200;
            margin-left: 12px;
            font-weight: 700;
            animation: myMove 1s infinite; //循环
            -webkit-animation: myMove 1s infinite;
            -moz-animation: myMove 1s infinite;
            -o-animation: myMove 1s infinite;
            cursor: pointer;
            &:hover {
              animation: none;
              // animation: myMove 1s infinite; //循环
              // -webkit-animation: myMove 1s infinite;
              // -moz-animation: myMove 1s infinite;
              // -o-animation: myMove 1s infinite;
            }
          }
        }
        .riri {
          width: 180px;
          height: 48px;
          border-radius: 4px;
          font-family: Microsoft YaHei;
          font-size: 20px;
          text-align: center;
          line-height: 48px;
          font-weight: normal;
          letter-spacing: 0em;
          color: #f8f9fb;
          background: linear-gradient(180deg, #0068d9 0%, #1377fe 100%);
          margin-left: 56px;
          cursor: pointer;
        }
        .ri_lis {
          .riribtn {
            display: none;
          }
        }
      }
    }
  }
}
// 小于800px
@media only screen and (max-width: 799px) {
  ._header {
    width: 100%;
    height: 60px;
    background-color: #fff;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999;
    display: flex;
    justify-content: space-between;
    align-items: center;
    ._mainer {
      width: 100%;
      height: 100%;
      padding: 0 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      ._img_lf {
        width: 34.53%;
        // width: 259px;
        height: 100%;
        display: flex;
        align-items: center;
        img {
          width: 100%;
          height: auto;
          object-fit: cover;
        }
      }
      ._main_ri {
        display: flex;
        align-items: center;
        ._rilf {
          display: none;
        }
        ._riri {
          // width: 111px;
          // height: 32px;
          // border-radius: 4px;
          // background-color: #0068d9;
          // font-family: Microsoft YaHei;
          // font-size: 16px;
          // color: #fff;
          // text-align: center;
          // line-height: 32px;
          display: none;
        }
        ._ri_lis {
          width: 111px;
          height: 32px;
          ._riribtn {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
@keyframes myMove {
  0% {
    transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
  }

  50% {
    transform: scale(1.2, 1.2);
    -moz-transform: scale(1.2, 1.2);
    -webkit-transform: scale(1.2, 1.2);
    -o-transform: scale(1.2, 1.2);
    -ms-transform: scale(1.2, 1.2);
  }

  100% {
    transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
  }
}
</style>
